type PermissionType = 'notification';

export const setUserRespondedPermissions = () => {
  localStorage.setItem('user-reponded-permissions', 'true');
  // for re-prompting notification permission for Critical Alerts (iOS)/Don't Disturb (Android) info
  localStorage.setItem('user-reponded-permissions2', 'true');
};

export const getUserRespondedPermissions = () => {
  return (
    localStorage.getItem('user-reponded-permissions') === 'true' &&
    localStorage.getItem('user-reponded-permissions2') === 'true'
  );
};

export const setUserPermission = (permission: PermissionType) => {
  localStorage.setItem(`user-${permission}-permission`, 'true');
};

export const getUserPermission = (permission: PermissionType) => {
  return (
    localStorage.getItem(`user-${permission}-permission`) === 'true' &&
    localStorage.getItem('user-reponded-permissions2') === 'true'
  );
};
