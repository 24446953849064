import { useEffect } from 'react';

import type { PluginListenerHandle } from '@capacitor/core';

import { isNative } from '../helpers/device.helper';
import { getDartAlertInfo } from '../pages/guest/firstAlertsSlice';
import { useAppDispatch } from '../store';

export const useDartAlertListener = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDartAlertInfo());
    let appListener: PluginListenerHandle;

    if (isNative()) {
      (async () => {
        const { App } = await import('@capacitor/app');
        appListener = await App.addListener('appStateChange', ({ isActive }) => {
          if (isActive) {
            dispatch(getDartAlertInfo());
          }
        });
      })();
    }

    return () => {
      if (isNative()) {
        appListener?.remove();
      }
    };
  }, [dispatch]);
};
