export const environment = {
  baseEndpoint: 'https://api-qa.talkingstick.app/',
  buildNumber: "28776478",  
  versionNumber: "2.1.7",  
  environment_deploy: "qa",  
  rayGunKey: 'hqaZDpkENyp0WOVqpDhdg',  
  localStorageKey: '.NtdmA2UV.e_BU2uK4RFReP7eJAJiGEEFMt.Q*6QatX@_At4FThg_hAoacuAp-KC4gDMVwQ9C*RvZk3kRkY*X9',  
  cryptoKey: 'xhf.tWZGHD*bN8q9QjM_qHJg-.6evj@qPEgT6egirrU_W_ZbfMnuxu6v@BGLgcca-Q3VwK@Kjrw*',  
  posthogToken: 'phc_7hJXVERK7T2Owd3OeGSDGUMeyTHBLKAjnBwQD3ZuwWJ',  
  orgCode: '',

  firebaseConfig: {
  apiKey: 'AIzaSyBPDMiYfNu_MK7krowyG-u9CKCv1F1t0a8',  
  authDomain: 'talking-stick-qa-aaf2c.firebaseapp.com',  
  projectId: 'talking-stick-qa-aaf2c',  
  storageBucket: 'talking-stick-qa-aaf2c.appspot.com',  
  messagingSenderId: '215305996060',  
  appId: '1:215305996060:web:0e6fc9e2ef278164ff9cd9',  
  vapidKey: 'BEgu-43tkAzs9FSpjT67JM-16TaE2bFmd15dqWB_eThcXK107sFvvKBpJoDD0XSPJTfkhpEAi-BhMkwhq_873mY',  
  },
};
