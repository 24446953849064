import { useCallback, useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

import { Capacitor, type PluginListenerHandle } from '@capacitor/core';

import { hasNotificationToken } from '../api/notification';
import { checkUpgrade, fetchGuestInfo } from '../components/updateApp/appStatusSlice';
import { environment } from '../environment/environment';
import { isNative, removeCookies, setOrgBodyClass } from '../helpers/device.helper';
import { initNativeNotification, initWebNotification } from '../helpers/notification.helper';
import {
  checkHasNewFirstAlertsCommunity,
  getAlertAcceptedDisclaimer,
  getAlertPromptResponse,
} from '../pages/guest/firstAlertsSlice';
import { endGuestLeftChat } from '../pages/guest/guestChatSlice';
import { useAppDispatch, useAppSelector } from '../store';

export const useInitApp = () => {
  const dispatch = useAppDispatch();
  const posthog = usePostHog();
  const appOrgCode = useAppSelector((state) => state.appStatusSlice.appOrgCode);
  const guestId = useAppSelector((state) => state.appStatusSlice.guestInfo?.guest_id);

  const reRegisterNotificationToken = useCallback(async () => {
    const notificationToken = await hasNotificationToken();

    if (!notificationToken) {
      if (isNative()) {
        initNativeNotification();
      } else {
        initWebNotification();
      }
    }
  }, []);

  useEffect(() => {
    document.getElementById('loader')?.remove();
    setOrgBodyClass();
    removeCookies();
    dispatch(fetchGuestInfo());
    dispatch(endGuestLeftChat());

    if (isNative()) {
      initNativeNotification();
      dispatch(checkUpgrade());
      dispatch(getAlertPromptResponse());
      dispatch(checkHasNewFirstAlertsCommunity());
      dispatch(getAlertAcceptedDisclaimer());
    }
  }, [dispatch]);

  useEffect(() => {
    reRegisterNotificationToken();
    let appListener: PluginListenerHandle;

    if (isNative() && Capacitor.isPluginAvailable('App')) {
      (async () => {
        const { App } = await import('@capacitor/app');
        appListener = await App.addListener('appStateChange', async ({ isActive }) => {
          if (isActive) {
            reRegisterNotificationToken();
          }
        });
      })();
    }

    return () => {
      appListener?.remove();
    };
  }, [reRegisterNotificationToken]);

  useEffect(() => {
    if (guestId) {
      posthog?.identify(guestId, {
        guest_id: guestId,
        organization_code: appOrgCode,
        platform: Capacitor.getPlatform(),
        version: environment.versionNumber,
      });
    } else {
      posthog?.reset();
    }
  }, [appOrgCode, guestId, posthog]);
};
