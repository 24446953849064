import type { AxiosError } from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getGuestThreadId, removeGuestThreadId, saveGuestThreadId } from '../../api/guest';
import { getNamespaceKey } from '../../components/guest/guest-activity-modals/GuestChatSessionHelpfulModal.component';
import type { Advocate } from '../../models/advocate';
import type { AdvocateInChat, ChatEndedBy, ChatUserConn } from '../../models/chat';
import { GUEST_COMPONENT } from '../../models/chat';
import { ChatPage } from '../../models/chat';
import type { ErrorMessage } from '../../models/Error';
import { type ChatSessionFeedback, type ChatSessionHelpful, GuestChatActivityModalStatus } from '../../models/guest';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun';
import { api } from '../../store';
import type { ReportedChatBy } from '../advocate/AdvocateSlice';
import { setLepsPageStatus, setShowLepsTabBar } from './lepsSlice';
import { setServicesPageStatus, setShowServicesTabBar } from './servicesSlice';

const { logError } = RaygunErrorHandlerService();

export const getEndedByFromReportedBy = (
  reportedBy: ReportedChatBy | undefined | null,
  defaultEndedBy: ChatEndedBy,
): ChatEndedBy => {
  switch (reportedBy) {
    case 'Abuse':
      return 'AdvocateReportedAbuse';
    case 'Emergency':
      return 'AdvocateReportedEmergency';
    default:
      return defaultEndedBy;
  }
};

type GuestChatSliceType = {
  guestChatUserConn: ChatUserConn | undefined;
  guestEndedChatBy: ChatEndedBy | undefined;
  guestAdvocateSentFirstMsg: boolean;
  guestChatSessionHelpfulTextKey: ReturnType<typeof getNamespaceKey>;
  isGuestTimeout: boolean;
  surveyThreadId: string | undefined;
  guestPromptShownTime: number | undefined;
  guestAdvocateIsOffline: boolean;
  advocateInChat: AdvocateInChat | undefined;
  guestChatActivityModalStatus: GuestChatActivityModalStatus;
};

const initialState: GuestChatSliceType = {
  guestChatUserConn: undefined,
  guestEndedChatBy: undefined,
  guestAdvocateSentFirstMsg: false,
  guestChatSessionHelpfulTextKey: 'GuestChatSessionHelpful.subTitleByGuestEndChat',
  isGuestTimeout: false,
  surveyThreadId: undefined,
  guestPromptShownTime: undefined,
  guestAdvocateIsOffline: false,
  advocateInChat: undefined,
  guestChatActivityModalStatus: GuestChatActivityModalStatus.IDLE,
};

type StartChat =
  | { advocate: AdvocateInChat; guest_chat_user_conn: ChatUserConn }
  | { advocate_unavailable: true }
  | false
  | undefined;

export const startChat = createAsyncThunk<StartChat, ChatPage, AsyncThunkConfig>(
  'guestChat/startChat',
  async (chatPage, thunkAPI) => {
    const forServiceProviders = chatPage === ChatPage.SERVICES;

    try {
      const { lepsSlice, servicesSlice } = thunkAPI.getState();
      const advocate_id = forServiceProviders
        ? servicesSlice.selectedServiceProvider?.advocate_id
        : lepsSlice.selectedLepsAdvocate?.advocate_id;
      if (advocate_id) {
        const response = await api.post<StartChat>('/guest/v1_start_chat', { advocate_id });
        let component = GUEST_COMPONENT.CHAT;
        if (!response || 'advocate_unavailable' in response) {
          component = GUEST_COMPONENT.CHAT_SESSION_ERROR;
        }
        if (forServiceProviders) {
          thunkAPI.dispatch(setShowServicesTabBar(true));
          thunkAPI.dispatch(setServicesPageStatus(component));
        } else {
          thunkAPI.dispatch(setShowLepsTabBar(true));
          thunkAPI.dispatch(setLepsPageStatus(component));
        }
        return response;
      }
    } catch (e) {
      if (forServiceProviders) {
        thunkAPI.dispatch(setShowServicesTabBar(true));
        thunkAPI.dispatch(setServicesPageStatus(GUEST_COMPONENT.CHAT_SESSION_ERROR));
      } else {
        thunkAPI.dispatch(setShowLepsTabBar(true));
        thunkAPI.dispatch(setLepsPageStatus(GUEST_COMPONENT.CHAT_SESSION_ERROR));
      }
      logError(e, [chatPage, 'guestChatSlice', 'startChat']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const startDartChat = createAsyncThunk<StartChat, undefined, AsyncThunkConfig>(
  'guestChat/startDartChat',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<StartChat>('/guest/v1_start_dart_chat');
      let component = GUEST_COMPONENT.CHAT;
      if (!response || 'advocate_unavailable' in response) {
        component = GUEST_COMPONENT.DART_CHAT_SESSION_ERROR;
      }
      thunkAPI.dispatch(setLepsPageStatus(component));
      return response;
    } catch (e) {
      thunkAPI.dispatch(setLepsPageStatus(GUEST_COMPONENT.DART_CHAT_SESSION_ERROR));
      logError(e, ['guestChatSlice', 'startDartChat']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const sendChatHelpful = createAsyncThunk<string | undefined, boolean, AsyncThunkConfig>(
  'guestChat/sendChatHelpful',
  async (helpful, thunkAPI) => {
    try {
      const ms_comm_thread_id = thunkAPI.getState().guestChatSlice.surveyThreadId;
      if (ms_comm_thread_id) {
        const params: ChatSessionHelpful = { ms_comm_thread_id, helpful };
        const response = await api.post<string>('/guest/v0_chat_helpful', params);
        return response;
      }
    } catch (e) {
      logError(e, ['guestChatSlice', 'sendChatHelpful']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const sendChatFeedback = createAsyncThunk<string | undefined, string | null | undefined, AsyncThunkConfig>(
  'guestChat/sendChatFeedback',
  async (feedback, thunkAPI) => {
    try {
      const ms_comm_thread_id = thunkAPI.getState().guestChatSlice.surveyThreadId;
      if (feedback) {
        const params: ChatSessionFeedback = { ms_comm_thread_id, feedback };
        const response = await api.post<string>('/guest/v0_chat_feedback', params);
        return response;
      }
    } catch (e) {
      logError(e, ['guestChatSlice', 'sendChatFeedback']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getGuestNewChat = createAsyncThunk<
  {
    advocate: Advocate;
    guest_chat_user_conn: ChatUserConn;
    chatPage: ChatPage;
  } | null,
  string,
  AsyncThunkConfig
>('guestChat/getGuestNewChat', async (thread_id, thunkAPI) => {
  try {
    const response = await api.post<{
      advocate: Advocate;
      guest_chat_user_conn: ChatUserConn;
    } | null>('/guest/v0_chat_request', { thread_id });
    if (response) {
      const chatPage = response.advocate.service_provider_organization_id ? ChatPage.SERVICES : ChatPage.LEPS;
      return { ...response, chatPage };
    }
    return null;
  } catch (e) {
    logError(e, ['guestChatSlice', 'getGuestNewChat']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const getGuestPageStatusComponent = (endedBy: ChatEndedBy) => {
  switch (endedBy) {
    case 'AdvocateTimeout':
    case 'AdvocateDecline':
      return GUEST_COMPONENT.CHAT_SESSION_ERROR;
    default:
      return GUEST_COMPONENT.ADVOCATE_SELECT;
  }
};

export const checkGuestChatEnded = createAsyncThunk<ChatEndedBy | undefined, undefined, AsyncThunkConfig>(
  'guestChat/getGuestChatEnded',
  async (_, thunkAPI) => {
    try {
      const { guestChatUserConn, advocateInChat } = thunkAPI.getState().guestChatSlice;
      const thread_id = guestChatUserConn?.threadId;
      if (thread_id) {
        const response = await api.post<ChatEndedBy | null>('/guest/v0_get_chat_ended', { thread_id });
        if (response) {
          const forServiceProviders = Boolean(advocateInChat?.service_provider_organization_id);
          const component = getGuestPageStatusComponent(response);
          if (forServiceProviders) {
            thunkAPI.dispatch(setServicesPageStatus(component));
          } else {
            thunkAPI.dispatch(setLepsPageStatus(component));
          }
          thunkAPI.dispatch(setGuestChatEndedBy(response));
        }
      }
    } catch (e) {
      logError(e, ['guestChatSlice', 'getGuestChatEnded']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const endGuestChat = createAsyncThunk<
  ReportedChatBy | undefined,
  { thread_id: string; ended_by: ChatEndedBy },
  AsyncThunkConfig
>('guestChat/endGuestChat', async ({ thread_id, ended_by }, thunkAPI) => {
  try {
    let endedBy = ended_by;
    const response = await api.post<ReportedChatBy | undefined>('/guest/v0_end_chat', { thread_id, ended_by });
    if (response) {
      endedBy = getEndedByFromReportedBy(response, endedBy);
    }
    const advocateInChat = thunkAPI.getState().guestChatSlice.advocateInChat;
    const forServiceProviders = Boolean(advocateInChat?.service_provider_organization_id);
    const component = getGuestPageStatusComponent(endedBy);
    if (forServiceProviders) {
      thunkAPI.dispatch(setServicesPageStatus(component));
    } else {
      thunkAPI.dispatch(setLepsPageStatus(component));
    }
    thunkAPI.dispatch(setGuestChatEndedBy(endedBy));
    return response;
  } catch (e) {
    logError(e, ['guestChatSlice', 'endGuestChat']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const endGuestLeftChat = createAsyncThunk<undefined, undefined, AsyncThunkConfig>(
  'guestChat/endGuestLeftChat',
  async (_, thunkAPI) => {
    try {
      const thread_id = getGuestThreadId();
      if (thread_id) {
        await api.post<boolean>('/guest/v0_end_left_chat', { thread_id });
        removeGuestThreadId();
      }
    } catch (e) {
      logError(e, ['guestChatSlice', 'endGuestLeftChat']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getGuestChatReportedByAdvocate = createAsyncThunk<
  ReportedChatBy | null | undefined,
  undefined,
  AsyncThunkConfig
>('guestChat/getGuestChatReportedByAdvocate', async (_, thunkAPI) => {
  try {
    const thread_id = thunkAPI.getState().guestChatSlice.guestChatUserConn?.threadId;
    if (thread_id) {
      const response = await api.post<ReportedChatBy | null>('/guest/v0_get_chat_reported_by_advocate', {
        thread_id,
      });
      return response;
    }
  } catch (e) {
    logError(e, ['guestChatSlice', 'getGuestChatReportedByAdvocate']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

/**
 * That slice is reset to the initial state when guests do the post-chat survey (PCS).
 * Don't add a state that should hold between chats.
 * Use guestSlice or appStatusSlice for that.
 */
export const guestChatSlice = createSlice({
  name: 'guestChat',
  initialState,
  reducers: {
    setGuestAdvocateSentFirstMsg: (state, action: PayloadAction<boolean>) => {
      state.guestAdvocateSentFirstMsg = action.payload;
    },
    setGuestChatUserConn: (state, action: PayloadAction<ChatUserConn | undefined>) => {
      state.guestChatUserConn = action.payload;
    },
    setGuestChatEndedBy: (state, action: PayloadAction<ChatEndedBy>) => {
      state.isGuestTimeout = action.payload === 'GuestTimeout';

      switch (action.payload) {
        case 'AdvocateTimeout':
        case 'AdvocateDecline':
          break;
        case 'CancelledByGuest':
          state.guestChatUserConn = undefined;
          break;
        default:
          state.guestChatActivityModalStatus = GuestChatActivityModalStatus.CHAT_HELPFUL;
          state.guestChatSessionHelpfulTextKey = getNamespaceKey(action.payload);
      }

      if (state.guestChatUserConn?.threadId) {
        state.surveyThreadId = state.guestChatUserConn.threadId;
      }
      state.guestEndedChatBy = undefined;
      state.guestChatUserConn = undefined;
      state.guestAdvocateSentFirstMsg = false;
      state.guestPromptShownTime = undefined;
      removeGuestThreadId();
    },
    setGuestPromptShownTime: (state, action: PayloadAction<number | undefined>) => {
      state.guestPromptShownTime = action.payload;
    },
    setGuestAdvocateIsOffline: (state, action: PayloadAction<boolean>) => {
      state.guestAdvocateIsOffline = action.payload;
    },
    setGuestChatActivityModalStatus: (state, action: PayloadAction<GuestChatActivityModalStatus>) => {
      state.guestChatActivityModalStatus = action.payload;
    },
    clearGuestChatState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(startChat.fulfilled, (state, action) => {
        if (action.payload) {
          if ('advocate_unavailable' in action.payload) {
            state.guestAdvocateIsOffline = true;
          } else {
            const guestChatUserConn = action.payload.guest_chat_user_conn;
            state.guestChatUserConn = guestChatUserConn;
            state.advocateInChat = action.payload.advocate;
            saveGuestThreadId(guestChatUserConn.threadId);
          }
        }
      })
      .addCase(startDartChat.fulfilled, (state, action) => {
        if (action.payload) {
          if ('advocate_unavailable' in action.payload) {
            state.guestAdvocateIsOffline = true;
          } else {
            const guestChatUserConn = action.payload.guest_chat_user_conn;
            state.guestChatUserConn = guestChatUserConn;
            state.advocateInChat = action.payload.advocate;
            saveGuestThreadId(guestChatUserConn.threadId);
          }
        }
      })
      .addCase(getGuestNewChat.fulfilled, (state, action) => {
        if (
          !state.guestEndedChatBy &&
          ((!state.guestChatUserConn?.threadId && action.payload?.guest_chat_user_conn.threadId) ||
            (!state.guestAdvocateSentFirstMsg && action.payload?.guest_chat_user_conn.firstAdvocateMsgSent))
        ) {
          state.guestChatUserConn = action.payload.guest_chat_user_conn;
          state.guestAdvocateSentFirstMsg = Boolean(action.payload.guest_chat_user_conn.firstAdvocateMsgSent);
        }
      });
  },
});

export const {
  setGuestAdvocateSentFirstMsg,
  setGuestChatUserConn,
  clearGuestChatState,
  setGuestChatEndedBy,
  setGuestPromptShownTime,
  setGuestAdvocateIsOffline,
  setGuestChatActivityModalStatus,
} = guestChatSlice.actions;
