import type { AxiosError, CanceledError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ABUSIVE_REASON_ID } from '../../constants';
import { AdvocatePostChatSurvey } from '../../models/advocate';
import type { ChatEndedBy, ChatUserConn } from '../../models/chat';
import type { ErrorMessage } from '../../models/Error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun';
import { api } from '../../store';
import { getMessageAlert } from './AdvocateChat.page';

const { logError } = RaygunErrorHandlerService();

export enum ADVOCATE_COMPONENT {
  POST_CHAT_SURVEY_1 = 'AdvocatePostChatSurvey1',
  POST_CHAT_SURVEY_2 = 'AdvocatePostChatSurvey2',
  POST_CHAT_SURVEY_3 = 'AdvocatePostChatSurvey3',
  CHAT = 'AdvocateChat',
  HOME = 'AdvocateHome',
}

type EndReasons = {
  id: string;
  reason: string;
};

type Subjects = {
  id: string;
  subject: string;
};

type AdvocateAlertMessage = {
  message: string;
  subHeader: string;
};

export type ReportedChatBy = 'Emergency' | 'Abuse';

export type AdvocateSliceType = {
  advocateIsAvailable: boolean;
  advocateSentFirstMsg: boolean;
  advocateChatUserConn: ChatUserConn | undefined;
  endReasons: EndReasons[];
  subjects: Subjects[];
  advocateChatPageStatus: ADVOCATE_COMPONENT;
  advocatePostChatSurvey: AdvocatePostChatSurvey;
  advocateEndedChatBy: ChatEndedBy | undefined;
  showAdvocateEndChatModal: boolean;
  advocateAlertMessage: AdvocateAlertMessage | undefined;
  guestReportedAbuse: boolean;
  openReportModal: boolean;
  advocateReportedChatBy: ReportedChatBy | null;
  advocateReportedAbuse: boolean;
};

const initialState: AdvocateSliceType = {
  advocateIsAvailable: false,
  advocateSentFirstMsg: false,
  advocateChatUserConn: undefined,
  endReasons: [],
  subjects: [],
  advocateEndedChatBy: undefined,
  advocateChatPageStatus: ADVOCATE_COMPONENT.HOME,
  advocatePostChatSurvey: new AdvocatePostChatSurvey(),
  showAdvocateEndChatModal: false,
  advocateAlertMessage: undefined,
  guestReportedAbuse: false,
  openReportModal: false,
  advocateReportedChatBy: null,
  advocateReportedAbuse: false,
};

export const verifyAdvocateToken = createAsyncThunk<boolean, undefined, AsyncThunkConfig>(
  'advocate/verifyAdvocateToken',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<boolean>('/advocate/v0_verify_token');
      return response ?? false;
    } catch (e) {
      logError(e, ['advocateSlice', 'verifyAdvocateToken']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAdvocateAvailableStatus = createAsyncThunk<boolean, undefined, AsyncThunkConfig>(
  'advocate/getAdvocateAvailableStatus',
  async (_, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => {
        source.cancel();
      });
      const response = await api.post<boolean>('/advocate/v1_get_status_available', undefined, {
        cancelToken: source.token,
      });
      return response ?? false;
    } catch (e) {
      if ((e as CanceledError<never>).code !== 'ERR_CANCELED') {
        logError(e, ['advocateSlice', 'getAdvocateAvailableStatus']);
      }
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const setAdvocateAvailable = createAsyncThunk<boolean, undefined, AsyncThunkConfig>(
  'advocate/setAdvocateAvailable',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<boolean>('/advocate/v1_set_status_available');
      return response ?? false;
    } catch (e) {
      logError(e, ['advocateSlice', 'setAdvocateAvailable']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const setAdvocateUnavailable = createAsyncThunk<boolean, undefined, AsyncThunkConfig>(
  'advocate/setAdvocateUnavailable',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<boolean>('/advocate/v1_set_status_unavailable');
      return response ?? false;
    } catch (e) {
      logError(e, ['advocateSlice', 'setAdvocateUnavailable']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAdvocateEndReasons = createAsyncThunk<EndReasons[], undefined, AsyncThunkConfig>(
  'advocate/getAdvocateEndReasons',
  async (_, thunkAPI) => {
    try {
      const response = await api.get<EndReasons[]>('/advocate/v0_chat_end_reasons');
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateSlice', 'getAdvocateEndReasons']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAdvocateSubjects = createAsyncThunk<Subjects[], undefined, AsyncThunkConfig>(
  'advocate/getAdvocateSubjects',
  async (_, thunkAPI) => {
    try {
      const response = await api.get<Subjects[]>('/advocate/v0_subjects');
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateSlice', 'getAdvocateSubjects']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const saveAdvocatePostChatSurvey = createAsyncThunk<
  boolean | 'time_expired',
  AdvocatePostChatSurvey,
  AsyncThunkConfig
>('advocate/saveAdvocatePostChatSurvey', async (advocatePostChatSurvey, thunkAPI) => {
  try {
    const response = await api.post<boolean>('/advocate/v0_post_chat_survey', advocatePostChatSurvey);
    return response ?? false;
  } catch (e) {
    logError(e, ['advocateSlice', 'saveAdvocatePostChatSurvey']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const getAdvocateNewChat = createAsyncThunk<ChatUserConn | undefined, undefined, AsyncThunkConfig>(
  'advocate/getAdvocateNewChat',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<ChatUserConn>('/advocate/v0_chat_request');
      return response;
    } catch (e) {
      logError(e, ['advocateSlice', 'getAdvocateNewChat']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAdvocateChatEnded = createAsyncThunk<ChatEndedBy | undefined, string, AsyncThunkConfig>(
  'advocate/getAdvocateChatEnded',
  async (thread_id, thunkAPI) => {
    try {
      const response = await api.post<ChatEndedBy>('/advocate/v0_get_chat_ended', { thread_id });
      return response;
    } catch (e) {
      logError(e, ['advocateSlice', 'getAdvocateChatEnded']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const saveAdvocateReportedChatBy = createAsyncThunk<
  string,
  { thread_id: string; reported_by_advocate: ReportedChatBy | null },
  AsyncThunkConfig
>('advocate/saveAdvocateReportedChatBy', async ({ thread_id, reported_by_advocate }, thunkAPI) => {
  try {
    const response = await api.post<string>('/advocate/v0_save_reported_by', { thread_id, reported_by_advocate });
    thunkAPI.dispatch(setAdvocateReportedBy(reported_by_advocate));
    return response ?? ''; // chat_id
  } catch (e) {
    logError(e, ['advocateSlice', 'saveAdvocateReportedChatBy']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const endAdvocateChat = createAsyncThunk<
  boolean,
  { thread_id: string; ended_by: ChatEndedBy },
  AsyncThunkConfig
>('advocate/endAdvocateChat', async ({ thread_id, ended_by }, thunkAPI) => {
  try {
    const {
      advocateSlice: { advocateReportedChatBy },
    } = thunkAPI.getState();
    const response = await api.post<boolean>('/advocate/v0_end_chat', {
      thread_id,
      ended_by,
      reported_by_advocate: advocateReportedChatBy,
    });
    thunkAPI.dispatch(setAdvocateEndedChatBy(ended_by));
    return response ?? false;
  } catch (e) {
    logError(e, ['advocateSlice', 'endAdvocateChat']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const advocateSlice = createSlice({
  name: 'advocate',
  initialState,
  reducers: {
    setAdvocateSentFirstMsg: (state, action: PayloadAction<boolean>) => {
      state.advocateSentFirstMsg = action.payload;
    },
    setAdvocateChatUserConn: (state, action: PayloadAction<ChatUserConn | undefined>) => {
      state.advocateChatUserConn = action.payload;
    },
    setAdvocateChatPageStatus: (state, action: PayloadAction<ADVOCATE_COMPONENT>) => {
      state.advocateChatPageStatus = action.payload;
    },
    setAdvocatePostChatSurvey: (state, action: PayloadAction<Partial<AdvocatePostChatSurvey>>) => {
      state.advocatePostChatSurvey = { ...state.advocatePostChatSurvey, ...action.payload };
    },
    setAdvocateEndedChatBy: (state, action: PayloadAction<ChatEndedBy>) => {
      state.guestReportedAbuse = action.payload === 'GuestReportedAbuse';

      if (
        action.payload.includes('Guest') ||
        action.payload === 'AdvocateTimeout' ||
        action.payload === 'AdvocateReportedAbuse'
      ) {
        const message = getMessageAlert(action.payload);
        state.advocateAlertMessage = message
          ? {
              message,
              subHeader:
                action.payload === 'AdvocateTimeout'
                  ? 'Your chat request has automatically closed.'
                  : 'Your chat session has ended',
            }
          : undefined;
      }

      switch (action.payload) {
        case 'AdvocateDecline':
        case 'AdvocateTimeout':
        case 'CancelledByGuest':
        case 'ErrorStartingChat':
          state.advocateChatPageStatus = ADVOCATE_COMPONENT.HOME;
          break;
        default:
          if (state.advocateChatUserConn?.threadId) {
            state.advocatePostChatSurvey.thread_id = state.advocateChatUserConn.threadId;
            state.advocateChatPageStatus = ADVOCATE_COMPONENT.POST_CHAT_SURVEY_1;
          }
      }

      state.advocateEndedChatBy = undefined;
      state.advocateChatUserConn = undefined;
      state.advocateSentFirstMsg = false;
    },
    setShowAdvocateEndChatModal: (state, action: PayloadAction<boolean>) => {
      state.showAdvocateEndChatModal = action.payload;
    },
    clearAdvocateAlertMessage: (state) => {
      state.advocateAlertMessage = undefined;
    },
    setOpenReportModal: (state, action: PayloadAction<boolean>) => {
      state.openReportModal = action.payload;
    },
    setAdvocateReportedBy: (state, action: PayloadAction<ReportedChatBy | null>) => {
      const isAbuse = action.payload === 'Abuse';
      state.advocatePostChatSurvey = {
        ...state.advocatePostChatSurvey,
        end_reason_id: isAbuse ? ABUSIVE_REASON_ID : '',
      };
      state.advocateReportedAbuse = isAbuse;
      state.advocateReportedChatBy = action.payload;
    },
    resetPostChatSurvey: (state) => {
      state.guestReportedAbuse = false;
      state.advocateReportedAbuse = false;
      state.advocateReportedChatBy = null;
      state.advocatePostChatSurvey = new AdvocatePostChatSurvey();
      state.advocateChatPageStatus = ADVOCATE_COMPONENT.HOME;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdvocateEndReasons.fulfilled, (state, action) => {
        state.endReasons = action.payload;
      })
      .addCase(getAdvocateSubjects.fulfilled, (state, action) => {
        state.subjects = action.payload;
      })
      .addCase(getAdvocateAvailableStatus.fulfilled, (state, action) => {
        state.advocateIsAvailable = action.payload;
      });
  },
});

export const {
  setAdvocateSentFirstMsg,
  setAdvocateChatUserConn,
  clearState,
  setAdvocateChatPageStatus,
  setAdvocatePostChatSurvey,
  setAdvocateEndedChatBy,
  setShowAdvocateEndChatModal,
  clearAdvocateAlertMessage,
  setOpenReportModal,
  setAdvocateReportedBy,
  resetPostChatSurvey,
} = advocateSlice.actions;
