import CryptoJS from 'crypto-js';

import { environment } from '../environment/environment';

export const getGuestThreadId = () => {
  const encrypted = localStorage.getItem('thread_id');
  if (encrypted) {
    const bytes = CryptoJS.AES.decrypt(encrypted, environment.cryptoKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  }
};

export const saveGuestThreadId = (thread_id: string) => {
  const encrypted = CryptoJS.AES.encrypt(thread_id, environment.cryptoKey);
  localStorage.setItem('thread_id', encrypted.toString());
};

export const removeGuestThreadId = () => {
  localStorage.removeItem('thread_id');
};
