import type { AxiosError } from 'axios';
import rg4js from 'raygun4js';

import { Device } from '@capacitor/device';

import { environment } from '../environment/environment';
import { getOrgCode } from '../helpers/device.helper';

const isLocal = environment.environment_deploy === 'local';

rg4js('options', {
  disableErrorTracking: isLocal,
});
rg4js('apiKey', environment.rayGunKey);
rg4js('setVersion', `${environment.versionNumber}.${environment.buildNumber}`);
rg4js('enableCrashReporting', !isLocal);
rg4js('withTags', [environment.environment_deploy, `v${environment.versionNumber}`]);
rg4js('filterSensitiveData', ['password', 'pass', 'new_password', 'old_password']);

export const RaygunErrorHandlerService = () => {
  const logError = (err: unknown, tags: string[], customData?: unknown) => {
    const error = err as AxiosError;
    if (error.response?.status) {
      tags = [error.response.status.toString(), ...tags];
    }

    const data = { error, tags, customData };

    if (!isLocal && navigator.onLine) {
      rg4js('send', data);
    }

    if (isLocal && !(error.response && error.response.status === 401)) {
      // eslint-disable-next-line no-console
      console.error(data);
    }
  };

  /**
   *
   * example:
   *
   * rg4js('setUser', {
   *  identifier: 'user_email_address@localhost.local',
   *  isAnonymous: false,
   *  email: 'emailaddress@localhost.local',
   *  firstName: 'Foo',
   *  fullName: 'Foo Bar',
   *  uuid: 'BAE62917-ACE8-ab3D-9287-B6A33B8E8C55'
   * });
   */

  const setRaygunUser = async () => {
    const { identifier } = await Device.getId();
    rg4js('setUser', {
      identifier: `device_id: ${identifier}, org_code: ${getOrgCode()}`,
    });
  };

  return { logError, setRaygunUser };
};
