import Cookies from 'js-cookie';

import { isPlatform } from '@ionic/core';
import type { AnimationBuilder } from '@ionic/react';
import { createAnimation } from '@ionic/react';

import { environment } from '../environment/environment';

export const isNative = () => (isPlatform('ios') || isPlatform('android')) && !isPlatform('mobileweb');

export const setDidOnboarding = () => {
  localStorage.setItem('didOnboarding', 'true');
};

export const getDidOnboarding = () => {
  return localStorage.getItem('didOnboarding') === 'true';
};

export const removeCookies = () => {
  const CookiesAPI = Cookies.withAttributes({ path: '/', domain: '.talkingstick.app' });
  const names = Object.keys(CookiesAPI.get());
  for (const name of names) {
    CookiesAPI.remove(name);
  }
};

export enum Org {
  Saskatchewan = 'sk',
  Test = 'test',
  BurnsWay = 'burns',
}

export const getOrgCode = () => {
  const url = window.location.origin;
  if (environment.orgCode) {
    return environment.orgCode as Org;
  } else if (url.includes('mytest')) {
    environment.orgCode = Org.Test;
    return Org.Test;
  } else if (url.includes('theburnsway')) {
    environment.orgCode = Org.BurnsWay;
    return Org.BurnsWay;
  } else {
    environment.orgCode = Org.Saskatchewan;
    return Org.Saskatchewan;
  }
};

export const setOrgBodyClass = () => {
  const orgCode = getOrgCode();
  if (orgCode !== Org.Saskatchewan) {
    document.body.classList.toggle(`${orgCode}-org`, true);
  }
};

export const getOrgImg = () => {
  switch (getOrgCode()) {
    case Org.Test:
      return {
        src: 'assets/imgs/canada-goose-test-org.webp',
        alt: 'Canada goose.',
      };
    default:
      return {
        src: 'assets/imgs/BISON.png',
        alt: 'Outline of a buffalo containing mountains, water, and sun moving through sky.',
      };
  }
};

export const getAnimationBuilder: AnimationBuilder = (baseEl, ops) => {
  const enteringAnimation = createAnimation()
    .addElement(ops.enteringEl)
    .fill('forwards')
    .duration(150)
    .fromTo('opacity', '0', '1');
  const leavingAnimation = createAnimation().addElement(ops.leavingEl).fill('forwards').fromTo('opacity', '1', '0');
  const animation = createAnimation().addElement(baseEl).addAnimation([enteringAnimation, leavingAnimation]);
  return animation;
};

export const getEvolvingMessageStatus = () => localStorage.getItem('seenEvolvingMessage');

export const setEvolvingMessageStatus = () => localStorage.setItem('seenEvolvingMessage', 'true');
