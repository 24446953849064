import { useLocation } from 'react-router';

import { ChatPage } from '../models/chat';

export const useChatPage = () => {
  const location = useLocation();
  const chatPage = location.pathname.split('/')[2] as ChatPage;
  const forServiceProviders = chatPage === ChatPage.SERVICES;
  return { chatPage, forServiceProviders } as const;
};
