import type { ToastOptions } from '@ionic/react';

export const CHAT_ERROR_CODES = [401, 403, 404];

export const LEAVING_ALERT_MESSAGE =
  'Are you sure you want to navigate away?\nYou will not be able to rejoin the chat.';

export const ABUSIVE_REASON_ID = '7bcde053-1924-4aa5-8496-51df6f494798';

export const ADVOCATE_REPORTED_ABUSE =
  'This chat has been reported by the peer advocate for violating our rules of engagement. The chat history has been saved.';
export const ADVOCATE_REPORTED_EMERGENCY =
  'As a safety measure, this chat has been reported by the peer advocate. The chat history has been saved.';

export const GUEST_REPORTED_ABUSE = 'The guest has reported abuse. The chat logs will be preserved.';

export const CHAT_CHECKS_INTERVAL = 30_000;
const GUEST_MINUTES_BEFORE_PROMPT = 4;
const GUEST_NO_PROMPT_RESPONSE_LIMIT_MINUTES = 2;
export const GUEST_TIMEOUT_TIME_LIMIT =
  (GUEST_MINUTES_BEFORE_PROMPT + GUEST_NO_PROMPT_RESPONSE_LIMIT_MINUTES) * 1000 * 60;
export const GUEST_NO_MSG_LIMIT = GUEST_MINUTES_BEFORE_PROMPT * 1000 * 60;
export const GUEST_NO_PROMPT_RESPONSE_LIMIT = GUEST_NO_PROMPT_RESPONSE_LIMIT_MINUTES * 1000 * 60;

const ADVOCATE_MINUTES_TO_START_CHAT = 5;
export const ADVOCATE_INITAL_MESSAGE_TIME_LIMIT = ADVOCATE_MINUTES_TO_START_CHAT * 1000 * 60;

export const ION_SELECT_INTERFACE_OPTIONS = {
  cssClass: 'app-ion-select-options',
  arrow: false,
  size: 'cover',
};

export const ADVOCATE_TOAST_OPTIONS: ToastOptions = {
  duration: 3000,
  cssClass: 'ios-safe-area-inset-top advocate-toast',
  position: 'top',
};
