import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Capacitor, type PluginListenerHandle } from '@capacitor/core';
import { IonToast } from '@ionic/react';

import './ErrorToast.scss';

import { useCapturePostHog } from '../../hooks/useCapturePostHog';
import { useAppDispatch, useAppSelector } from '../../store';
import { setExpiredTokenErrorToast, setNetworkErrorToast, setNoInternetErrorToast } from '../updateApp/appStatusSlice';

export const ErrorToast = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const showNoInternetErrorToast = useAppSelector((state) => state.appStatusSlice.showNoInternetErrorToast);
  const showNetworkErrorToast = useAppSelector((state) => state.appStatusSlice.showNetworkErrorToast);
  const showExpiredTokenErrorToast = useAppSelector((state) => state.appStatusSlice.showExpiredTokenErrorToast);

  useEffect(() => {
    let appListener: PluginListenerHandle;
    const onlineEventHandler = () => {
      dispatch(setNoInternetErrorToast(false));
    };
    const offlineEventHandler = () => {
      dispatch(setNoInternetErrorToast(true));
    };

    if (Capacitor.isPluginAvailable('Network')) {
      (async () => {
        const { Network } = await import('@capacitor/network');
        appListener = await Network.addListener('networkStatusChange', (status) => {
          dispatch(setNoInternetErrorToast(!status.connected));
        });
      })();
    } else {
      window.addEventListener('online', onlineEventHandler);
      window.addEventListener('offline', offlineEventHandler);
    }

    return () => {
      appListener?.remove();
      window.removeEventListener('online', onlineEventHandler);
      window.removeEventListener('offline', offlineEventHandler);
    };
  }, [dispatch]);

  return (
    <>
      <IonToast
        isOpen={showNoInternetErrorToast}
        onDidDismiss={() => {
          capturePostHogCustomEvent('ErrorToastComponent dismissed no internet toast');
          dispatch(setNoInternetErrorToast(false));
        }}
        header={t('generic.noInternetText')}
        position="bottom"
        color="danger"
        cssClass="errorToast"
        buttons={[{ text: t('generic.ok'), role: 'cancel' }]}
      />
      <IonToast
        isOpen={showNetworkErrorToast}
        onDidDismiss={() => {
          capturePostHogCustomEvent('ErrorToastComponent dismissed network error toast');
          dispatch(setNetworkErrorToast(false));
        }}
        header={t('generic.noNetworkText')}
        position="bottom"
        color="danger"
        cssClass="errorToast"
        buttons={[{ text: t('generic.ok'), role: 'cancel' }]}
      />
      {/* Only for advocates */}
      <IonToast
        isOpen={showExpiredTokenErrorToast}
        onDidDismiss={() => {
          dispatch(setExpiredTokenErrorToast(false));
          capturePostHogCustomEvent('ErrorToastComponent dismissed expired error token toast');
        }}
        header="Session timeout"
        message="You have been logged out due to inactivity. Please log in again."
        position="bottom"
        color="danger"
        cssClass="errorToast"
        buttons={[{ text: t('generic.ok'), role: 'cancel' }]}
      />
    </>
  );
};
