import type { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router';

import { getAuthResult } from '../api/advocate';
import { environment } from '../environment/environment';

const isProd = environment.environment_deploy === 'prod';

export const AuthRoute = ({ children, ...rest }: RouteProps) => {
  if (!getAuthResult()?.token) {
    return <Redirect to="/advocate-login" />;
  }

  return <Route {...rest}>{children}</Route>;
};

export const DesignRoute = ({ children, ...rest }: RouteProps) => {
  if (isProd) {
    return <Redirect to="/guest/chat" />;
  }

  return <Route {...rest}>{children}</Route>;
};
