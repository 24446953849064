import type { Language } from '../models/language';

export const languages: Language[] = [
  { language_code: 'nsk', name: 'ᐃᔪᐤ ᐃᔨᒧᐅᓐ', english_name: 'Naskapi', order: 65 },
  //{ language_code: 'ojw', name: 'Anihšināpēmowin', english_name: 'Saulteaux', order: 80 },
  // { language_code: 'dak', name: 'Dakhótiyapi', english_name: 'Dakota', order: 10 },
  { language_code: 'xnd', name: 'Denesųłiné', english_name: 'Dene', order: 50 },
  { language_code: 'en', name: 'English', english_name: 'English', order: 20 },
  { language_code: 'es', name: 'Español', english_name: 'Spanish', order: 15 },
  { language_code: 'fr', name: 'Français', english_name: 'French', order: 25 },
  { language_code: 'moe', name: 'Innu Aimun', english_name: 'Innu', order: 85 },
  { language_code: 'lkt', name: "Lakhól'iyapi", english_name: 'Lakota', order: 30 },
  { language_code: 'crs', name: 'Maskékowak', english_name: 'Swampy Cree', order: 40 },
  // { language_code: 'asb', name: 'Nakotan Siouan', english_name: 'Nakota', order: 60 },
  { language_code: 'crk', name: 'Nehiyawēwin', english_name: 'Plains Cree', order: 70 },
  { language_code: 'cwd', name: 'Nihithawīwin', english_name: 'Woodland Cree', order: 75 },
];
