import { chatbubbles } from 'ionicons/icons';

import { IonCol, IonIcon, IonRow } from '@ionic/react';

const GuestWaiting = () => {
  return (
    <IonRow className="ion-margin ion-justify-content-center">
      <IonCol sizeLg="2.5" className="ion-padding guest-waiting">
        <IonRow>
          <IonCol size="1">
            <IonIcon icon={chatbubbles} />
          </IonCol>
          <IonCol size="11">Guest is waiting for you to start the conversation...</IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default GuestWaiting;
