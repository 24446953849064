import { useEffect, useState } from 'react';

import type { PluginListenerHandle } from '@capacitor/core';

import { hasNotificationToken } from '../api/notification';
import { isNative } from '../helpers/device.helper';
import { checkNotificationPermission } from '../helpers/notification.helper';

export const useCheckNotification = () => {
  const [isBrowserNotificationSupported, setIsBrowserNotificationSupported] = useState(true);
  const [hasNotificationPermission, setHasNotificationPermission] = useState(true);
  const [hasNotificationTokenSaved, setHasNotificationTokenSaved] = useState(true);

  useEffect(() => {
    (async () => {
      const { isSupported } = await import('@firebase/messaging');

      setIsBrowserNotificationSupported(await isSupported());
      setHasNotificationPermission(await checkNotificationPermission());
      setHasNotificationTokenSaved(await hasNotificationToken());
    })();
  }, []);

  useEffect(() => {
    let appListener: PluginListenerHandle;

    if (isNative()) {
      (async () => {
        const { App } = await import('@capacitor/app');
        appListener = await App.addListener('appStateChange', async ({ isActive }) => {
          if (isActive) {
            setHasNotificationPermission(await checkNotificationPermission());
            setHasNotificationTokenSaved(await hasNotificationToken());
          }
        });
      })();
    }

    return () => {
      appListener?.remove();
    };
  }, []);

  return { isBrowserNotificationSupported, hasNotificationPermission, hasNotificationTokenSaved };
};
