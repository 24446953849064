import { useEffect, useRef, useState } from 'react';

import type { ChatAdapter } from '@azure/communication-react';
import { ChatComposite } from '@azure/communication-react';
import { IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';

import './Chat.component.scss';

import { avatarPersonaData, customTheme } from '../../helpers/chat.helper';

export const Chat = ({ chatAdapter }: { chatAdapter: ChatAdapter }) => {
  const contentRef = useRef<HTMLIonContentElement>(null);
  const [chatContentHeight, setChatContentHeight] = useState(0);

  useEffect(() => {
    if (!contentRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setChatContentHeight(contentRef.current?.clientHeight || 0);
    });
    resizeObserver.observe(contentRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <>
      <IonContent ref={contentRef} fullscreen className="chat-content ph-no-capture" scrollY={false}>
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-justify-content-center ">
            <IonCol className="chat-col" style={{ height: chatContentHeight - 32 }}>
              <ChatComposite
                fluentTheme={customTheme}
                onFetchAvatarPersonaData={() => Promise.resolve(avatarPersonaData)}
                options={{ topic: false }}
                adapter={chatAdapter}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};
