import { useEffect } from 'react';
import type { History, LocationListener } from 'history';

import { hasNotificationToken } from '../api/notification';
import { isNative } from '../helpers/device.helper';
import { initWebNotification } from '../helpers/notification.helper';
import { history } from '../history';

export const useWebNotificationListener = () => {
  useEffect(() => {
    let unlisten: ReturnType<History['listen']> | undefined = undefined;

    if (!isNative()) {
      const historyListener: LocationListener = async (_location) => {
        const notificationToken = await hasNotificationToken();
        if (!notificationToken) {
          initWebNotification();
        }
      };

      unlisten = history.listen(historyListener);
    }

    return () => {
      unlisten?.();
    };
  }, []);
};
