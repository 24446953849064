/* Core CSS required for Ionic components to work properly */
import { lazy, Suspense } from 'react';

import { setIconOptions } from '@fluentui/react/lib/Styling';
import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactHashRouter } from '@ionic/react-router';

import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.scss';
import './theme/common.scss';
import './App.scss';

import AppUrlListener from './AppUrlListener';
import { ErrorToast } from './components/ErrorToast/ErrorToast';
import { isNative } from './helpers/device.helper';
import { history } from './history';
import { useDartAlertListener, useInitApp } from './hooks';
import { MiddlewareRouter } from './routes/Middleware.router';
import { RaygunErrorHandlerService } from './services/raygun';

setIconOptions({ disableWarnings: true });
setupIonicReact({ innerHTMLTemplatesEnabled: true, swipeBackEnabled: false, hardwareBackButton: false });
RaygunErrorHandlerService().setRaygunUser();

const NotificationPermissionModal = lazy(() => import('./components/Permissions/NotificationPermissionModal'));
const AndroidExplainerModal = lazy(() => import('./components/Permissions/AndroidExplainerModal'));
const AppStatusModal = lazy(() => import('./components/updateApp/AppStatusModal'));
const FirstAlertsModals = lazy(() => import('./components/FirstAlerts/FirstAlertsModals'));
const FirstAlertModal = lazy(() => import('./components/FirstAlerts/FirstAlertModal'));
const FirstAlertDrawer = lazy(() => import('./components/FirstAlerts/FirstAlertDrawer'));
const EvolvingMessageModal = lazy(() => import('./components/updateApp/EvolvingMessageModal'));
const CommunityNotFoundModal = lazy(() => import('./components/FirstAlerts/CommunityNotFoundModal'));

const App = () => {
  useInitApp();
  useDartAlertListener();

  return (
    <IonApp>
      <IonReactHashRouter history={history}>
        <Suspense>
          {isNative() && (
            <>
              <NotificationPermissionModal />
              <AndroidExplainerModal />
              <EvolvingMessageModal />
              <AppStatusModal />
              <FirstAlertsModals />
              <FirstAlertModal />
              <CommunityNotFoundModal />
              <FirstAlertDrawer />
            </>
          )}
        </Suspense>
        <AppUrlListener />
        <ErrorToast />
        <MiddlewareRouter />
      </IonReactHashRouter>
    </IonApp>
  );
};

export default App;
