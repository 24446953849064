import { type ReactNode } from 'react';

import { IonCol, IonHeader, IonRow, IonToolbar } from '@ionic/react';

import { useKeyboardShown } from '../../hooks';
import type { PageColor } from '../../theme/GradientHeader';

export const ChatHeader = ({
  forServiceProviders,
  leftContent,
  endButton,
  reportAbuseCheckbox,
}: {
  forServiceProviders: boolean;
  leftContent: ReactNode;
  endButton: ReactNode;
  reportAbuseCheckbox?: ReactNode;
}) => {
  const keyboardShown = useKeyboardShown();
  const headerColor: Omit<PageColor, 'yellow'> = forServiceProviders ? 'blue' : 'brown';

  return (
    <IonHeader className="ion-no-border chat-header">
      <IonToolbar mode="ios" className={`ts-${headerColor}-bg ion-no-padding`}>
        <IonRow
          className={`ion-justify-content-center ion-align-items-center ts-${headerColor}-bg ${
            keyboardShown ? 'keyboard-shown' : ''
          }`}
        >
          <IonCol className="ion-no-padding" sizeLg="4">
            <IonRow className="ion-align-items-center ion-justify-content-between ion-padding">
              <IonCol size="6" className="ion-align-self-center ion-no-padding">
                {leftContent}
              </IonCol>
              <IonCol className="ion-no-padding" size="6">
                <div className="report-end-chat-col">
                  {reportAbuseCheckbox}
                  {endButton}
                </div>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonToolbar>
    </IonHeader>
  );
};
