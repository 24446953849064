import type React from 'react';

import { IonButton } from '@ionic/react';

import './TSButton.scss';

export type ButtonVariant =
  | 'default'
  | 'red'
  | 'blue'
  | 'blue-60'
  | 'cyan'
  | 'underline'
  | 'underline-light'
  | 'white'
  | 'black'
  | 'brown';

type IonButtonProps = React.ComponentProps<typeof IonButton>;

interface TSButtonProps extends IonButtonProps {
  variant?: ButtonVariant;
  disabled?: boolean;
  shadow?: boolean;
}

export const TSButton: React.FC<TSButtonProps> = ({
  variant = 'red',
  disabled = false,
  shadow = false,
  children,
  className = '',
  ...props
}) => {
  const combinedClassNames = `title-small ts-button ${disabled ? 'disabled' : ''} ${shadow ? 'ts-shadow' : ''} ts-${variant} ${className}`;

  return (
    <IonButton disabled={disabled} className={combinedClassNames} {...props}>
      {children}
    </IonButton>
  );
};
