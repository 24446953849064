import { IonHeader, IonToolbar } from '@ionic/react';

export type PageColor = 'blue' | 'yellow' | 'brown';

export const GradientHeader = ({ pageColor }: { pageColor?: PageColor }) => {
  return (
    <IonHeader className="ion-no-border">
      <IonToolbar mode="ios" className={`ts-${pageColor}-bg ion-no-padding gradient-toolbar`}></IonToolbar>
    </IonHeader>
  );
};
