import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { environment } from './environment/environment';
import { languages } from './helpers/language.helper';
import crk from './translations/crk/translation.json';
import crs from './translations/crs/translation.json';
import cwd from './translations/cwd/translation.json';
import dak from './translations/dak/translation.json';
import en from './translations/en/translation.json';
import es from './translations/es/translation.json';
import fr from './translations/fr/translation.json';
import lkt from './translations/lkt/translation.json';
import moe from './translations/moe/translation.json';
import nsk from './translations/nsk/translation.json';
import ojw from './translations/ojw/translation.json';
import xnd from './translations/xnd/translation.json';

export const resources = {
  crk: { translation: crk },
  crs: { translation: crs },
  cwd: { translation: cwd },
  dak: { translation: dak },
  es: { translation: es },
  en: { translation: en },
  fr: { translation: fr },
  lkt: { translation: lkt },
  ojw: { translation: ojw },
  xnd: { translation: xnd },
  nsk: { translation: nsk },
  moe: { translation: moe },
} as const;

export type EnglishTranslation = (typeof resources)['en']['translation'];

const isLocal = environment.environment_deploy === 'local';
const supportedLngs = languages.map((l) => l.language_code);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    fallbackLng: 'en',
    supportedLngs: supportedLngs,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
    debug: isLocal && !(process.env.MODE === 'test'),
  });
