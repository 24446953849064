import CryptoJS from 'crypto-js';

import type { ToastOptions } from '@ionic/react';
import type { HookOverlayOptions } from '@ionic/react/dist/types/hooks/HookOverlayOptions';

import { ADVOCATE_TOAST_OPTIONS } from '../constants';
import { environment } from '../environment/environment';
import type { ChangePasswordParams, ForgotPassword, Login, ResetPassword } from '../models/advocate';
import { AuthResult } from '../models/advocate';
import { RaygunErrorHandlerService } from '../services/raygun';
import { api } from '../store';

const { logError } = RaygunErrorHandlerService();

export const advocateLogin = async (login: Login): Promise<AuthResult> => {
  try {
    const response = await api.post<AuthResult>('/advocate/v0_login', login);
    if (response?.token) {
      saveAuthResult(response);
    }
    return response ?? new AuthResult();
  } catch (error) {
    logError(error, ['api/advocate', 'advocateLogin']);
    return new AuthResult();
  }
};

export const refreshToken = async (): Promise<AuthResult> => {
  try {
    const response = await api.post<AuthResult>('/advocate/v0_refresh_token');
    if (response?.token) {
      saveAuthResult(response);
    }
    return response ?? new AuthResult();
  } catch (error) {
    logError(error, ['api/advocate', 'refreshToken']);
    return new AuthResult();
  }
};

export const advocateForgotPassword = async (forgotPassword: ForgotPassword): Promise<boolean | undefined> => {
  try {
    const response = await api.post<boolean>('/advocate/v0_forgot_password', forgotPassword);
    return response;
  } catch (error) {
    logError(error, ['api/advocate', 'advocateForgotPassword']);
    return undefined;
  }
};

export const advocateResetPassword = async (
  resetPassword: ResetPassword,
  present: (options: ToastOptions & HookOverlayOptions) => Promise<void>,
): Promise<AuthResult> => {
  try {
    const response = await api.post<AuthResult>('/advocate/v0_reset_password', resetPassword);
    if (response?.token) {
      present({ ...ADVOCATE_TOAST_OPTIONS, message: 'Password reset successfully!' });
      saveAuthResult(response);
    }
    return response ?? new AuthResult();
  } catch (error) {
    logError(error, ['api/advocate', 'advocateResetPassword']);
    return new AuthResult();
  }
};

export const changePassword = async (
  chnagePasswordParams: ChangePasswordParams,
  present: (options: ToastOptions & HookOverlayOptions) => Promise<void>,
): Promise<AuthResult> => {
  try {
    const response = await api.post<AuthResult>('/advocate/v0_change_password', chnagePasswordParams);
    if (response?.token) {
      present({ ...ADVOCATE_TOAST_OPTIONS, message: 'Password changed successfully!' });
      saveAuthResult(response);
    }
    return response ?? new AuthResult();
  } catch (error) {
    logError(error, ['api/advocate', 'advocateResetPassword']);
    return new AuthResult();
  }
};

export const advocateVerifyToken = async (): Promise<boolean | undefined> => {
  try {
    const response = await api.post<boolean>('/advocate/v0_verify_token');
    return response;
  } catch (error) {
    logError(error, ['api/advocate', 'advocateVerifyToken']);
    return undefined;
  }
};

export const advocatePhotoUpload = async (photoBase64Str: string): Promise<boolean | undefined> => {
  try {
    const response = await api.post<boolean>('/advocate/v0_update_photo', { photoBase64Str });
    return response;
  } catch (error) {
    logError(error, ['api/advocate', 'advocatePhotoUpload']);
    return undefined;
  }
};

export const advocatePhotoDelete = async (): Promise<boolean | undefined> => {
  try {
    const response = await api.post<boolean>('/advocate/v0_delete_photo');
    return response;
  } catch (error) {
    logError(error, ['api/advocate', 'advocatePhotoDelete']);
    return undefined;
  }
};

export const getAuthResult = () => {
  const encryptedAuthResult = localStorage.getItem(environment.localStorageKey);
  if (encryptedAuthResult) {
    const bytes = CryptoJS.AES.decrypt(encryptedAuthResult, environment.cryptoKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted) as AuthResult;
  }
};

export const saveAuthResult = (authResult: AuthResult) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(authResult), environment.cryptoKey);
  localStorage.setItem(environment.localStorageKey, encrypted.toString());
};

export const removeAuthResult = () => {
  localStorage.removeItem(environment.localStorageKey);
};
