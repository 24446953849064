import { useCallback, useEffect, useRef, useState } from 'react';

import type { ChatAdapter, MessageReceivedListener, ParticipantsRemovedListener } from '@azure/communication-react';
import { IonButton, IonCheckbox } from '@ionic/react';

import { recordMessageTimestamp } from '../../api/chat';
import {
  ADVOCATE_INITAL_MESSAGE_TIME_LIMIT,
  CHAT_CHECKS_INTERVAL,
  CHAT_ERROR_CODES,
  GUEST_REPORTED_ABUSE,
  GUEST_TIMEOUT_TIME_LIMIT,
} from '../../constants';
import { useCapturePostHog } from '../../hooks/useCapturePostHog';
import type { ChatAdapterError, ChatEndedBy } from '../../models/chat';
import {
  endAdvocateChat,
  getAdvocateChatEnded,
  saveAdvocateReportedChatBy,
  setAdvocateEndedChatBy,
  setAdvocateSentFirstMsg,
  setOpenReportModal,
  setShowAdvocateEndChatModal,
} from '../../pages/advocate/AdvocateSlice';
import { RaygunErrorHandlerService } from '../../services/raygun';
import { useAppDispatch, useAppSelector } from '../../store';
import { Chat } from '../chat/Chat.component';
import { ChatHeader } from '../chat/ChatHeader';
import GuestWaiting from './GuestWaiting';
import { ReportChatModal } from './ReportChatModal';

const { logError } = RaygunErrorHandlerService();

export const AdvocateChat = ({ advocateChatAdapter }: { advocateChatAdapter: ChatAdapter | undefined }) => {
  const dispatch = useAppDispatch();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const advocateChatUserConn = useAppSelector((state) => state.advocateSlice.advocateChatUserConn);
  const advocateSentFirstMsg = useAppSelector((state) => state.advocateSlice.advocateSentFirstMsg);
  const advocateEndedChatBy = useAppSelector((state) => state.advocateSlice.advocateEndedChatBy);
  const openReportModal = useAppSelector((state) => state.advocateSlice.openReportModal);
  const advocateReportedChatBy = useAppSelector((state) => state.advocateSlice.advocateReportedChatBy);
  const [guestSentFirstMsg, setGuestSentFirstMsg] = useState(Boolean(advocateChatUserConn?.lastGuestMsgTime));
  const [startChatTime] = useState(Date.now());
  const lastGuestMessageTimeRef = useRef(advocateChatUserConn?.lastGuestMsgTime);

  const finishChat = useCallback(
    async (endedBy: ChatEndedBy, triggeredBy: 'GuestSide' | 'AdvocateSide') => {
      if (advocateChatUserConn?.threadId) {
        if (triggeredBy === 'AdvocateSide') {
          try {
            await advocateChatAdapter?.removeParticipant(advocateChatUserConn.communicationUserId);
          } catch (error) {
            logError(error, ['AdvocateChat', 'finishChat']);
          }
        }
        advocateChatAdapter?.dispose();
        await dispatch(endAdvocateChat({ thread_id: advocateChatUserConn.threadId, ended_by: endedBy }));
        capturePostHogCustomEvent('AdvocateChatComponent advocate ended chat', {
          thread_id: advocateChatUserConn.threadId,
          ended_by: endedBy,
        });
      }
    },
    [
      advocateChatAdapter,
      advocateChatUserConn?.communicationUserId,
      advocateChatUserConn?.threadId,
      capturePostHogCustomEvent,
      dispatch,
    ],
  );

  const checkAdvocateTimeout = useCallback(() => {
    const timeNow = Date.now();
    const diff = timeNow - startChatTime;
    const advocate_has_timed_out = diff >= ADVOCATE_INITAL_MESSAGE_TIME_LIMIT;

    if (advocate_has_timed_out) {
      finishChat('AdvocateTimeout', 'AdvocateSide');
    }
  }, [finishChat, startChatTime]);

  const hasGuestTimedOut = useCallback(() => {
    if (lastGuestMessageTimeRef.current) {
      const timeNow = Date.now();
      const diff = timeNow - lastGuestMessageTimeRef.current;
      const guest_has_timed_out = diff >= GUEST_TIMEOUT_TIME_LIMIT;
      return guest_has_timed_out;
    }

    return false;
  }, []);

  const checkGuestTimeout = useCallback(() => {
    if (hasGuestTimedOut()) {
      finishChat('GuestTimeout', 'AdvocateSide');
    }
  }, [finishChat, hasGuestTimedOut]);

  const getChatEnded = useCallback(async () => {
    if (advocateChatUserConn?.threadId) {
      const response = await dispatch(getAdvocateChatEnded(advocateChatUserConn.threadId));
      if (getAdvocateChatEnded.fulfilled.match(response) && response.payload) {
        dispatch(setAdvocateEndedChatBy(response.payload));
      }
    }
  }, [advocateChatUserConn?.threadId, dispatch]);

  // Listeners
  useEffect(() => {
    const participantsRemovedHandler: ParticipantsRemovedListener = (listener) => {
      if (listener.removedBy.displayName === 'Guest') {
        if (!advocateSentFirstMsg) {
          checkAdvocateTimeout();
        } else {
          advocateChatAdapter?.dispose();

          if (!advocateEndedChatBy) {
            if (hasGuestTimedOut()) {
              dispatch(setAdvocateEndedChatBy('GuestTimeout'));
            } else {
              dispatch(setAdvocateEndedChatBy('Guest'));
            }
          }
        }
      }
    };
    const messageSentHandler: MessageReceivedListener = (_listener) => {
      if (!advocateSentFirstMsg) {
        capturePostHogCustomEvent('AdvocateChatComponent advocate started chat with guest', {
          thread_id: advocateChatUserConn?.threadId,
        });
        dispatch(setAdvocateSentFirstMsg(true));
        lastGuestMessageTimeRef.current = Date.now();
      }
    };
    const messageReceivedHandler: MessageReceivedListener = (listener) => {
      if (advocateChatUserConn?.threadId) {
        recordMessageTimestamp(
          advocateChatUserConn.threadId,
          listener.message.id,
          listener.message.senderDisplayName || '',
        );
      }

      if (listener.message.senderDisplayName === 'Guest') {
        lastGuestMessageTimeRef.current = Date.now();
        setGuestSentFirstMsg(true);
        if (listener.message.content?.message === GUEST_REPORTED_ABUSE) {
          dispatch(setAdvocateEndedChatBy('GuestReportedAbuse'));
        }
      }
    };
    const endChatOnError = () => {
      advocateChatAdapter?.dispose();
      dispatch(setAdvocateEndedChatBy('GuestLeftChat'));
    };
    const errorHandler = (e: ChatAdapterError) => {
      if (e.innerError.statusCode && CHAT_ERROR_CODES.includes(e.innerError.statusCode)) {
        endChatOnError();
      }
      logError(e, ['AdvocateChat', 'ChatAdapterCommunication', 'errorHandler']);
    };
    const windowErrorHandler = (e: PromiseRejectionEvent) => {
      if (CHAT_ERROR_CODES.includes(e.reason.statusCode)) {
        endChatOnError();
      }
      logError(e, ['AdvocateChat', 'ChatAdapterCommunication', 'windowErrorHandler']);
    };

    if (advocateChatAdapter) {
      advocateChatAdapter.on('participantsRemoved', participantsRemovedHandler);
      advocateChatAdapter.on('messageSent', messageSentHandler);
      advocateChatAdapter.on('messageReceived', messageReceivedHandler);
      advocateChatAdapter.on('error', errorHandler);
      window.addEventListener('unhandledrejection', windowErrorHandler);
    }

    return () => {
      if (advocateChatAdapter) {
        advocateChatAdapter.off('participantsRemoved', participantsRemovedHandler);
        advocateChatAdapter.off('messageSent', messageSentHandler);
        advocateChatAdapter.off('messageReceived', messageReceivedHandler);
        advocateChatAdapter.off('error', errorHandler);
        window.removeEventListener('unhandledrejection', windowErrorHandler);
      }
    };
  }, [
    advocateChatAdapter,
    advocateChatUserConn?.threadId,
    advocateEndedChatBy,
    advocateSentFirstMsg,
    checkAdvocateTimeout,
    dispatch,
    hasGuestTimedOut,
    capturePostHogCustomEvent,
  ]);

  // setInterval
  useEffect(() => {
    const setIntervalId = setInterval(() => {
      if (!advocateSentFirstMsg) {
        checkAdvocateTimeout();
      } else {
        checkGuestTimeout();
      }
      getChatEnded();
    }, CHAT_CHECKS_INTERVAL);

    return () => {
      clearInterval(setIntervalId);
    };
  }, [advocateSentFirstMsg, checkAdvocateTimeout, checkGuestTimeout, getChatEnded]);

  return (
    <>
      <ChatHeader
        forServiceProviders={false} // All "brown" for now. TODO: PM-6878
        leftContent={<div className="headline-small">Live chat</div>}
        endButton={
          <IonButton
            className="defaultButtonRed"
            mode="ios"
            shape="round"
            size="small"
            onClick={() => {
              capturePostHogCustomEvent('AdvocateChat advocate opened end chat modal');
              dispatch(setShowAdvocateEndChatModal(true));
            }}
          >
            End
          </IonButton>
        }
        reportAbuseCheckbox={
          (guestSentFirstMsg || advocateChatUserConn?.lastGuestMsgTime) && (
            <IonCheckbox
              id="report-abuse-checkbox"
              mode="ios"
              labelPlacement="end"
              checked={openReportModal || Boolean(advocateReportedChatBy)}
              onIonChange={async (e) => {
                if (!e.detail.checked) {
                  if (advocateChatUserConn?.threadId) {
                    await dispatch(
                      saveAdvocateReportedChatBy({
                        thread_id: advocateChatUserConn.threadId,
                        reported_by_advocate: null,
                      }),
                    );
                  }
                }

                dispatch(setOpenReportModal(e.detail.checked));
                capturePostHogCustomEvent('AdvocateChat advocate opened report abuse/emergency modal');
              }}
            >
              Report
            </IonCheckbox>
          )
        }
      />
      {!advocateSentFirstMsg && <GuestWaiting />}
      {advocateChatAdapter && <Chat chatAdapter={advocateChatAdapter} />}
      <ReportChatModal />
    </>
  );
};
