import { useEffect, useState } from 'react';
import type { Location } from 'history';
import { Prompt, useHistory } from 'react-router-dom';

import type { AlertButton } from '@ionic/react';
import { IonAlert } from '@ionic/react';

import { useCapturePostHog } from '../hooks/useCapturePostHog';

export const RouteLeavingGuardAlert = ({
  when,
  message,
  cancelText,
  okText,
  navigate,
  shouldBlockNavigation,
}: {
  when?: boolean;
  message: string;
  cancelText: string;
  okText: string;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}) => {
  const history = useHistory();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const [visible, setVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick: AlertButton['handler'] = (_value) => {
    setVisible(false);
    setConfirmedNavigation(true);
  };

  const closeAlert: AlertButton['handler'] = (_value) => {
    // Set the browser url back
    capturePostHogCustomEvent('RouteLeavingGuardAlert dismissed alert');
    history.replace(history.location);
    setVisible(false);
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <IonAlert
        isOpen={visible}
        onDidDismiss={closeAlert}
        buttons={[
          {
            text: cancelText,
            handler: closeAlert,
          },
          {
            text: okText,
            handler: handleConfirmNavigationClick,
          },
        ]}
        message={message}
      />
    </>
  );
};
