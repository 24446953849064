import { forwardRef, type ReactNode } from 'react';

import { IonAccordion, IonAccordionGroup, IonItem, IonLabel } from '@ionic/react';

import './TSAccordion.scss';

type IonAccordionProps = React.ComponentProps<typeof IonAccordion>;
type IonAccordionGroupProps = React.ComponentProps<typeof IonAccordionGroup>;

export interface TSAccordionInfo {
  id?: string;
  label: string | ReactNode;
  content: string | string[] | ReactNode;
}
interface TSAccordionProps extends IonAccordionGroupProps {
  accordionList: TSAccordionInfo[];
  accordionProps?: IonAccordionProps;
}

export const TSAccordion = forwardRef<HTMLIonAccordionGroupElement, TSAccordionProps>(function BasicTSAccordion(
  { accordionList, className = '', accordionProps = {}, ...accordionGroupProps }: TSAccordionProps,
  ref,
) {
  const combinedClassName = `title-small ${className}`;

  return (
    <IonAccordionGroup ref={ref} {...accordionGroupProps} className="ts-accordion">
      {accordionList.map(({ id, label, content }, index) => (
        <IonAccordion key={index} value={id} className={combinedClassName} {...accordionProps}>
          <IonItem id={`${id}-label`} slot="header">
            <IonLabel> {label}</IonLabel>
          </IonItem>
          <div className="content" slot="content">
            {content}
          </div>
        </IonAccordion>
      ))}
    </IonAccordionGroup>
  );
});
