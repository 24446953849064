import { forwardRef } from 'react';

import './GuestActivityModal.scss';

import { TSModal } from '../../atomic';
import type { TSModalProps } from '../../atomic/TSModal/TSModal';

export const GuestActivityModal = forwardRef<HTMLIonModalElement, TSModalProps>(function BasicGuestActivityModal(
  { isOpen, closeModal, mainContent, ...props }: TSModalProps,
  ref,
) {
  return (
    <TSModal
      ref={ref}
      mode="md"
      id="guest-chat-activity-modal"
      backdropDismiss={false}
      closeButton={false}
      closeModal={closeModal}
      isOpen={isOpen}
      mainContent={mainContent}
      {...props}
    />
  );
});
