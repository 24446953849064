import { useCallback } from 'react';
import type { CaptureOptions, Properties } from 'posthog-js';
import { usePostHog } from 'posthog-js/react';

import { getAuthResult } from '../api/advocate';

export const useCapturePostHog = () => {
  const posthog = usePostHog();

  const capturePostHogCustomEvent = useCallback(
    (eventName: string, customProperties?: Properties | null | undefined) => {
      const options: CaptureOptions = { timestamp: new Date() };
      const properties: Properties = {
        role: getAuthResult()?.token ? 'Advocate' : 'Guest',
        ...customProperties,
      };
      posthog?.capture(eventName, properties, options);
    },
    [posthog],
  );

  return { capturePostHogCustomEvent } as const;
};
