import { useEffect, useState } from 'react';

import type { PluginListenerHandle } from '@capacitor/core';

import { isNative } from '../helpers/device.helper';

export const useKeyboardShown = () => {
  const [shown, setShown] = useState(false);

  useEffect(() => {
    let keyboardDidShowListener: PluginListenerHandle;
    let keyboardDidHideListener: PluginListenerHandle;

    if (isNative()) {
      (async () => {
        const { Keyboard } = await import('@capacitor/keyboard');
        keyboardDidShowListener = await Keyboard?.addListener('keyboardDidShow', (_info) => {
          setShown(true);
        });

        keyboardDidHideListener = await Keyboard?.addListener('keyboardDidHide', () => {
          setShown(false);
        });
      })();
    }

    return () => {
      if (isNative()) {
        keyboardDidShowListener?.remove();
        keyboardDidHideListener?.remove();
      }
    };
  }, []);

  return shown;
};
