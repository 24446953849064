import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { IonAlert, IonModal, IonPage, useIonLoading } from '@ionic/react';

import './AdvocateChat.scss';

import { AdvocateChat } from '../../components/advocate/AdvocateChat.component';
import { AdvocateEndChat } from '../../components/advocate/AdvocateEndChat.component';
import { AdvocateHomePage } from '../../components/advocate/AdvocateHome.component';
import { AdvocateChatSessionEndedPart1 } from '../../components/advocate/AdvocatePostChatSurvey/AdvocateChatSessionEndedPart1.component';
import { AdvocateChatSessionEndedPart2 } from '../../components/advocate/AdvocatePostChatSurvey/AdvocateChatSessionEndedPart2.component';
import { AdvocateChatSessionEndedPart3 } from '../../components/advocate/AdvocatePostChatSurvey/AdvocateChatSessionEndedPart3.component';
import { ADVOCATE_REPORTED_ABUSE, ADVOCATE_REPORTED_EMERGENCY, LEAVING_ALERT_MESSAGE } from '../../constants';
import { useCreateChatAdapter } from '../../hooks';
import { useBeforeUnload } from '../../hooks/useBeforeUnload';
import { useCapturePostHog } from '../../hooks/useCapturePostHog';
import type { ChatEndedBy } from '../../models/chat';
import { RouteLeavingGuardAlert } from '../../routes/RouteLeavingGuardAlert';
import { RaygunErrorHandlerService } from '../../services/raygun';
import { logout, useAppDispatch, useAppSelector } from '../../store';
import { GradientHeader } from '../../theme/GradientHeader';
import {
  ADVOCATE_COMPONENT,
  clearAdvocateAlertMessage,
  endAdvocateChat,
  setShowAdvocateEndChatModal,
} from './AdvocateSlice';

const { logError } = RaygunErrorHandlerService();

export const getMessageAlert = (endedChatBy: ChatEndedBy | undefined) => {
  switch (endedChatBy) {
    case 'Guest':
    case 'GuestLeftChat':
      return '<p>The guest has left this chat. <br/><br/> Please select OK to complete the post-chat survey.</p>';
    case 'CancelledByGuest':
      return '<p>The guest cancelled this chat.</p>';
    case 'AdvocateTimeout':
      return '<p>You did not respond to this chat request within 5 minutes.</p>';
    case 'GuestTimeout':
      return '<p>The guest has been inactive for more than 6 minutes. <br/><br/> Please select OK to complete the post-chat survey.</p>';
    case 'AdvocateReportedAbuse':
      return '<p>You have reported abuse. The chat logs will be preserved.</p>';
    case 'GuestReportedAbuse':
      return '<p>The guest has reported abuse. The chat logs will be preserved.</p>';
  }
};

const AdvocateChatPage = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useAppDispatch();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const [present, dismiss] = useIonLoading();
  const advocateChatPageStatus = useAppSelector((state) => state.advocateSlice.advocateChatPageStatus);
  const advocateChatUserConn = useAppSelector((state) => state.advocateSlice.advocateChatUserConn);
  const advocatePostChatSurvey = useAppSelector((state) => state.advocateSlice.advocatePostChatSurvey);
  const showAdvocateEndChatModal = useAppSelector((state) => state.advocateSlice.showAdvocateEndChatModal);
  const advocateAlertMessage = useAppSelector((state) => state.advocateSlice.advocateAlertMessage);
  const advocateSentFirstMsg = useAppSelector((state) => state.advocateSlice.advocateSentFirstMsg);
  const advocateReportedChatBy = useAppSelector((state) => state.advocateSlice.advocateReportedChatBy);
  const advocateChatAdapter = useCreateChatAdapter(advocateChatUserConn);
  const hasGradientHeader = ![ADVOCATE_COMPONENT.CHAT].includes(advocateChatPageStatus);
  useBeforeUnload(advocateChatUserConn?.threadId || advocatePostChatSurvey.thread_id);

  const endChatModal = useCallback(
    async (abuse?: boolean) => {
      const endedBy: ChatEndedBy = abuse
        ? 'AdvocateReportedAbuse'
        : advocateSentFirstMsg
          ? 'Advocate'
          : 'AdvocateDecline';
      present();
      if (advocateChatAdapter && advocateChatUserConn?.threadId) {
        try {
          if (advocateReportedChatBy === 'Abuse') {
            await advocateChatAdapter.sendMessage(ADVOCATE_REPORTED_ABUSE);
            capturePostHogCustomEvent('AdvocateChatPage advocate reported abuse', {
              thread_id: advocateChatUserConn.threadId,
            });
          }
          if (advocateReportedChatBy === 'Emergency') {
            await advocateChatAdapter.sendMessage(ADVOCATE_REPORTED_EMERGENCY);
            capturePostHogCustomEvent('AdvocateChatPage advocate reported emergency', {
              thread_id: advocateChatUserConn.threadId,
            });
          }
          await advocateChatAdapter.removeParticipant(advocateChatUserConn.communicationUserId);
        } catch (error) {
          logError(error, ['AdvocateChatPage', 'endChatModal']);
        }
        advocateChatAdapter.dispose();
      }
      if (advocateChatUserConn?.threadId && endedBy) {
        await dispatch(endAdvocateChat({ thread_id: advocateChatUserConn.threadId, ended_by: endedBy }));
        capturePostHogCustomEvent('AdvocateChatPage advocate ended chat', {
          ended_by: endedBy,
          thread_id: advocateChatUserConn.threadId,
        });
      }
      dispatch(setShowAdvocateEndChatModal(false));
      dismiss();
    },
    [
      advocateChatAdapter,
      advocateChatUserConn?.communicationUserId,
      advocateChatUserConn?.threadId,
      advocateReportedChatBy,
      advocateSentFirstMsg,
      dismiss,
      dispatch,
      present,
      capturePostHogCustomEvent,
    ],
  );

  const showComponent = () => {
    switch (advocateChatPageStatus) {
      case ADVOCATE_COMPONENT.HOME:
        return <AdvocateHomePage />;
      case ADVOCATE_COMPONENT.CHAT:
        return <AdvocateChat advocateChatAdapter={advocateChatAdapter} />;
      case ADVOCATE_COMPONENT.POST_CHAT_SURVEY_1:
        return <AdvocateChatSessionEndedPart1 />;
      case ADVOCATE_COMPONENT.POST_CHAT_SURVEY_2:
        return <AdvocateChatSessionEndedPart2 />;
      case ADVOCATE_COMPONENT.POST_CHAT_SURVEY_3:
        return <AdvocateChatSessionEndedPart3 />;
      default:
        return <AdvocateHomePage />;
    }
  };

  return (
    <IonPage>
      {hasGradientHeader && <GradientHeader pageColor="brown" />}
      {showComponent()}

      {advocateAlertMessage && (
        <IonAlert
          isOpen={Boolean(advocateAlertMessage)}
          onDidDismiss={() => dispatch(clearAdvocateAlertMessage())}
          backdropDismiss={false}
          message={advocateAlertMessage.message}
          header={'Oops!'}
          subHeader={advocateAlertMessage.subHeader}
          buttons={['OK']}
        />
      )}

      <IonModal
        className="fullscreenModal"
        isOpen={showAdvocateEndChatModal}
        animated={false}
        canDismiss={true}
        onDidDismiss={() => dispatch(setShowAdvocateEndChatModal(false))}
      >
        {advocateChatAdapter && (
          <AdvocateEndChat
            endChatModal={endChatModal}
            closeModal={() => {
              capturePostHogCustomEvent('AdvocateChatPage closed end chat modal');
              dispatch(setShowAdvocateEndChatModal(false));
            }}
            chatAdapter={advocateChatAdapter}
            isAdvocate={true}
            advocateSentFirstMsg={advocateSentFirstMsg}
          />
        )}
      </IonModal>

      <RouteLeavingGuardAlert
        message={LEAVING_ALERT_MESSAGE}
        cancelText="Cancel"
        okText="OK"
        navigate={async (path) => {
          await endChatModal();

          if (path === '/advocate/logout') {
            await logout(dispatch);
            history.push('/advocate-login');
          } else {
            history.push(path);
          }
        }}
        shouldBlockNavigation={(location) => {
          // We don't block moving between tabs, only if they move away from the advocate tabs
          const rootTabToGo = location.pathname.split('/')[1];
          const currentRootTab = match.url.split('/')[1];
          return (
            Boolean(advocateChatUserConn) &&
            (rootTabToGo !== currentRootTab || location.pathname === '/advocate/logout')
          );
        }}
      />
    </IonPage>
  );
};

export default AdvocateChatPage;
