import type { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { ErrorMessage } from '../../models/Error';
import type { Community } from '../../models/guest';
import type { Language } from '../../models/language';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun';
import { api } from '../../store';

const { logError } = RaygunErrorHandlerService();

type GuestSliceType = {
  communityList: Community[];
  languageList: Language[];
  guestIsBlocked: boolean | undefined;
};

const initialState: GuestSliceType = {
  communityList: [],
  languageList: [],
  guestIsBlocked: undefined,
};

export const getCommunityList = createAsyncThunk<Community[], undefined, AsyncThunkConfig>(
  'guest/getCommunityList',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<Community[]>('/guest/v1_get_communities');
      return response ?? [];
    } catch (e) {
      logError(e, ['guestSlice', 'getCommunityList']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getLanguageList = createAsyncThunk<Language[], undefined, AsyncThunkConfig>(
  'guest/getLanguageList',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<Language[]>('/guest/v1_get_languages');
      return response ?? [];
    } catch (e) {
      logError(e, ['guestSlice', 'getLanguageList']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getGuestBlockedStatus = createAsyncThunk<boolean | undefined, undefined, AsyncThunkConfig>(
  'guest/getGuestBlockedStatus',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<boolean>('/guest/v0_get_blocked_status');
      return response;
    } catch (e) {
      logError(e, ['guestSlice', 'getGuestBlockedStatus']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCommunityList.fulfilled, (state, action) => {
        state.communityList = action.payload;
      })
      .addCase(getLanguageList.fulfilled, (state, action) => {
        state.languageList = action.payload;
      })
      .addCase(getGuestBlockedStatus.fulfilled, (state, action) => {
        state.guestIsBlocked = action.payload;
      });
  },
});

// export const {} = guestSlice.actions;
