import type { CommunicationTokenRefreshOptions } from '@azure/communication-common';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';

import type { ChatEndedBy, TokenResponse } from '../models/chat';
import { RaygunErrorHandlerService } from '../services/raygun';
import { api } from '../store';
import { getAuthResult } from './advocate';

const { logError } = RaygunErrorHandlerService();

// reference: https://docs.microsoft.com/en-us/javascript/api/overview/azure/communication-common-readme?view=azure-node-latest
export const createAutoRefreshingCredential = (userId: string, token: string) => {
  const options: CommunicationTokenRefreshOptions = {
    token: token,
    tokenRefresher: refreshTokenAsync(userId),
    refreshProactively: true,
  };
  return new AzureCommunicationTokenCredential(options);
};

const refreshTokenAsync = (userIdentity: string) => {
  return async (): Promise<string> => {
    const url = getAuthResult()?.token ? '/advocate/v0_chat_refresh_token' : '/guest/v0_chat_refresh_token';
    const response = await api.post<TokenResponse>(url, {
      communicationUserId: userIdentity,
    });
    return response?.token ?? '';
  };
};

export const canTakeSurvey = async (thread_id: string) => {
  try {
    const response = await api.post<{ time_expired: boolean; reported_abuse: boolean }>(
      '/advocate/v0_can_take_survey',
      { thread_id },
    );
    return response;
  } catch (error) {
    logError(error, ['api/chat', 'canTakeSurvey']);
  }
};

export const chatIsValid = async (thread_id: string) => {
  try {
    const response = await api.post<{
      valid: boolean;
      ended_by: ChatEndedBy;
    }>('/advocate/v0_chat_is_valid', { thread_id });
    return response;
  } catch (error) {
    logError(error, ['api/chat', 'chatIsValid']);
  }
};

export const recordMessageTimestamp = async (thread_id: string, message_id: string, sender: string) => {
  try {
    const response = await api.post<number>('/advocate/v0_chat_message_sent', { thread_id, message_id, sender });
    return response;
  } catch (error) {
    logError(error, ['api/chat', 'recordMessageTimestamp']);
  }
};
